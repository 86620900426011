import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import './stripe.css';
import { withTrans } from '../../i18n/withTrans';
import useAuthContext from '../../contexts/AuthContext';
import KRGlue from '@lyracom/embedded-form-glue';
import { Helmet } from 'react-helmet';
import { useHandleResize } from '../../utils';
import PopUpErrors from '../ReservationPage/PopUpErrors';
import { useTranslation } from 'react-i18next';

const isBrowser = typeof window !== 'undefined';

const languageEquivalent = {
  en: 'en-US',
  fr: 'fr-FR',
  es: 'es-ES'
};

const SystemPay = ({
  state,
  setMessageTitle,
  setMessage,
  reservationDispatch
}) => {
  const { t, i18n } = useTranslation();
  const { dispatchAPI } = useAuthContext();
  const [formToken, setFormToken] = useState();
  const [sales, setSales] = useState([]);
  const [salesNew, setSalesNew] = useState([]);
  const [salesOld, setSalesOld] = useState([]);
  const [alertOpen, setAlertOpen] = useState(false);
  const [errorText, setErrortext] = useState('general');
  const { width } = useHandleResize();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [formDisplayed, setFormDisplayed] = useState(false);

  const sendDebug = () => {
    dispatchAPI('POST', {
      url: '/debug',
      body: {
        location: 'SystemPay.js line 36',
        state: state?.lignePanierList,
        localStorage: localStorage
      }
    });
  };

  useEffect(() => {
    sendDebug();
  }, [state]);

  useEffect(() => {
    setTimeout(() => {
      if (!state?.lignePanierList?.length) {
        setMessageTitle('failed_title2');
        setMessage('failed2');
        setTimeout(() => {
          window.location.href = '/';
        }, 2000);
      }
    }, 2000);
  }, [state.lignePanierList]);

  let full_price = '0';
  let reduced_price = '0';
  let child_price = '0';
  let free_price = '0';

  if (isBrowser) {
    full_price = JSON.parse(localStorage.getItem('full_price'));
    reduced_price = JSON.parse(localStorage.getItem('reduced_price'));
    child_price = JSON.parse(localStorage.getItem('child_price'));
    free_price = JSON.parse(localStorage.getItem('free_price'));
  }

  const createOrder = async (body) => {
    try {
      const { data } = await dispatchAPI('POST', { url: `/orders`, body });
      return data;
    } catch (e) {
      setAlertOpen(true);
      setErrortext('general');
      if (process.env.NODE_ENV === 'production') {
        try {
          await fetch(`${process.env.GATSBY_API_URL}/gitlab-issues`, {
            method: 'POST',
            body: JSON.stringify({
              error: {
                title: `[CREATE-ORDER]: ${e?.message}`,
                description: e.toString()
              },
              path: window.location.href
            }),
            headers: new Headers({ 'Content-type': 'application/json' })
          });
        } catch (e) {
          // eslint-disable-next-line no-console
          console.error("Impossible de signaler l'erreur");
        }
      }
    }
  };

  const updateOrder = async (body, id) => {
    try {
      await dispatchAPI('PATCH', { url: `/orders/${id}`, body });
      return true;
    } catch (e) {
      setAlertOpen(true);
      setErrortext('general');
      if (process.env.NODE_ENV === 'production') {
        try {
          await fetch(`${process.env.GATSBY_API_URL}/gitlab-issues`, {
            method: 'POST',
            body: JSON.stringify({
              error: {
                title: `[UPDATE-ORDER]: ${e?.message}`,
                description: e.toString()
              },
              path: window.location.href
            }),
            headers: new Headers({ 'Content-type': 'application/json' })
          });
        } catch (e) {
          // eslint-disable-next-line no-console
          console.error("Impossible de signaler l'erreur");
        }
      }
    }
  };

  const createContact = async (body) => {
    try {
      const { data } = await dispatchAPI('POST', { url: `/gts/contact`, body });
      return data;
    } catch (e) {
      setAlertOpen(true);
      setErrortext('general');
      if (process.env.NODE_ENV === 'production') {
        try {
          await fetch(`${process.env.GATSBY_API_URL}/gitlab-issues`, {
            method: 'POST',
            body: JSON.stringify({
              error: {
                title: `[CREATE-GTS-CONTACT]: ${e?.message}`,
                description: e.toString()
              },
              path: window.location.href
            }),
            headers: new Headers({ 'Content-type': 'application/json' })
          });
        } catch (e) {
          // eslint-disable-next-line no-console
          console.error("Impossible de signaler l'erreur");
        }
      }
    }
  };

  const createSale = async (body) => {
    try {
      const { data } = await dispatchAPI('POST', { url: `/gts/vente`, body });
      return data;
    } catch (e) {
      setAlertOpen(true);
      setErrortext('general');
      if (process.env.NODE_ENV === 'production') {
        try {
          await fetch(`${process.env.GATSBY_API_URL}/gitlab-issues`, {
            method: 'POST',
            body: JSON.stringify({
              error: {
                title: `[CREATE-GTS-ORDER]: ${e?.message}`,
                description: e.toString()
              },
              path: window.location.href
            }),
            headers: new Headers({ 'Content-type': 'application/json' })
          });
        } catch (e) {
          // eslint-disable-next-line no-console
          console.error("Impossible de signaler l'erreur");
        }
      }
    }
  };

  const checkProductCode = (item) => {
    switch (item) {
      case '17':
        return 'IOMXVGTP01';
      case 'full_price':
        return 'IOADVGTP01';
      case 'reduced_price':
        return 'IOMXVGTR01';
      case 'child_price':
        return 'IOENVGTR01';
      default:
        return 'IOENVGTG01';
    }
  };

  useEffect(() => {
    const list = [];
    const listOld = [];
    list.push(
      ...(state?.ticketsList || []).map((ticket) => ({
        codeproduit: checkProductCode(
          ticket?.visitType === '17' ? '17' : ticket?.visitorType
        ),
        quantity:
          ticket?.visitorType === 'full_price'
            ? full_price
            : ticket?.visitorType === 'reduced_price'
            ? reduced_price
            : ticket?.visitorType === 'child_price'
            ? child_price
            : free_price,
        unitPrice:
          ticket?.visitType === '17'
            ? '26'
            : ticket?.visitorType === 'full_price'
            ? '23'
            : ticket?.visitorType === 'reduced_price'
            ? '16.5'
            : ticket?.visitorType === 'child_price'
            ? '10'
            : '0',
        totalPrice: JSON.parse(ticket?.price),
        codePanier: state?.lignePanierList || [],
        participants: state.visitorsList
          .filter((visitor) => visitor.visitorType === ticket?.visitorType)
          .map((el) => ({
            cab: '699990100086',
            ...el,
            email: el.email || '',
            pays: el.pays || '',
            nationalite: '',
            lieunaissance: '',
            datenaissance: el.datenaissance || ''
          }))
      }))
    );

    if (state.oldReservation) {
      listOld.push(
        ...(state.oldReservation.ticketsList || []).map((ticket) => ({
          codeproduit: checkProductCode(ticket?.visitorType),
          quantity:
            ticket?.visitorType === 'full_price'
              ? state.oldReservation.full_price
              : ticket?.visitorType === 'reduced_price'
              ? state.oldReservation.reduced_price
              : ticket?.visitorType === 'child_price'
              ? state.oldReservation.child_price
              : state.oldReservation.free_price,
          unitPrice: JSON.stringify(ticket?.price),
          totalPrice:
            ticket?.price *
            (ticket?.visitorType === 'full_price'
              ? JSON.stringify(full_price)
              : ticket?.visitorType === 'reduced_price'
              ? JSON.stringify(reduced_price)
              : ticket?.visitorType === 'child_price'
              ? JSON.stringify(child_price)
              : JSON.stringify(free_price)),
          codePanier: state?.oldReservation?.lignePanierList || [],
          participants: state.oldReservation.visitorsList
            .filter((visitor) => visitor.visitorType === ticket?.visitorType)
            .map((el) => ({
              cab: '699990100086',
              ...el,
              email: el.email || '',
              pays: el.pays || '',
              nationalite: '',
              lieunaissance: '',
              datenaissance: el.datenaissance || ''
            }))
        }))
      );
    }
    setSalesOld(listOld);
    setSalesNew(list);
    setSales([...list, ...listOld]);
  }, [state.ticketsList, state.oldReservation]);

  const getFormToken = async () => {
    try {
      const customer = state?.visitorsList?.[0];
      const { data } = await dispatchAPI('POST', {
        url: `/systempay/token`,
        body: {
          amount: parseFloat(state?.totalPrice) * 100,
          currency: 'EUR',
          customer: {
            email: customer?.email,
            billingDetails: {
              firstName: customer?.prenom,
              lastName: customer?.nom,
              phoneNumber: customer?.telephone
            }
          },
          metadata: {
            customer,
            booking: state?.selectedSlot,
            cartItems: state?.lignePanierList || []
          }
        }
      });
      setFormToken(data.formToken);
    } catch (e) {
      setAlertOpen(true);
      setErrortext('systempayForm');
      if (process.env.NODE_ENV === 'production') {
        try {
          await fetch(`${process.env.GATSBY_API_URL}/gitlab-issues`, {
            method: 'POST',
            body: JSON.stringify({
              error: {
                title: `[GET-FORM-TOKEN]: ${e?.message}`,
                description: e.toString()
              },
              path: window.location.href
            }),
            headers: new Headers({ 'Content-type': 'application/json' })
          });
        } catch (e) {
          // eslint-disable-next-line no-console
          console.error("Impossible de signaler l'erreur");
        }
      }
    }
  };

  const updateCartItems = async (cartItems) => {
    try {
      await dispatchAPI('PATCH', {
        url: '/cartItems/many',
        body: {
          cartItems: (cartItems || []).map((el) => el._id),
          status: 'PAID'
        }
      });
    } catch (e) {
      setAlertOpen(true);
      setErrortext('general');
      if (process.env.NODE_ENV === 'production') {
        try {
          await fetch(`${process.env.GATSBY_API_URL}/gitlab-issues`, {
            method: 'POST',
            body: JSON.stringify({
              error: {
                title: `[UPDATE-CART-ITEM]: ${e?.message}`,
                description: e.toString()
              },
              path: window.location.href
            }),
            headers: new Headers({ 'Content-type': 'application/json' })
          });
        } catch (e) {
          // eslint-disable-next-line no-console
          console.error("Impossible de signaler l'erreur");
        }
      }
    }
  };

  let isFormAttached = false;

  const displayPaymentForm = async () => {
    if (isFormAttached) return;
    isFormAttached = true;

    try {
      const loadedLibrary = await KRGlue.loadLibrary(
        process.env.GATSBY_SYSTEMPAY_ENDPOINT,
        process.env.GATSBY_SYSTEMPAY_KEY
      );

      const formConfig = await loadedLibrary.KR.setFormConfig({
        formToken: formToken,
        'kr-language':
          // eslint-disable-next-line react/prop-types
          languageEquivalent[i18n.language] || state.language || 'en-US'
      });

      const loadedForm = await formConfig.KR.attachForm(
        `${
          width > 993 ? '.web_render_div' : '.mobile_render_div'
        } #myPaymentForm`
      );

      loadedForm.KR.smartForm.onClick(({ action }) => {
        if (action === 'beforePaymentStart') {
          if (isSubmitting) {
            return false;
          }
          setIsSubmitting(true);
          return true;
        }
        return true;
      });

      loadedForm.KR.onSubmit(async (response) => {
        if (isSubmitting) return;
        setIsSubmitting(true);
        try {
          if (response.clientAnswer.orderStatus === 'PAID') {
            // STEP 1 : Créer la commande dans l'API
            const order = await createOrder({
              ...state,
              language: localStorage.getItem('language_site'),
              receiverList: (state?.receiverList || []).filter(
                (el) => el?.email
              ),
              sales: salesNew,
              payload: response.clientAnswer,
              totalPrice:
                state?.totalPrice - (state?.oldReservation?.totalPrice || 0)
            });

            // STEP 2 : Mettre à jour les articles du panier
            await updateCartItems([
              ...(state?.lignePanierList || []),
              ...(state?.oldReservation?.lignePanierList || [])
            ]);

            let orderOld = {};
            if (state.oldReservation) {
              // STEP 3 : Créer une commande pour l'ancienne réservation si nécessaire
              orderOld = await createOrder({
                ...state.oldReservation,
                language: localStorage.getItem('language_site'),
                receiverList: (state?.receiverList || []).filter(
                  (el) => el.email
                ),
                sales: salesOld,
                payload: response.clientAnswer
              });
            }

            // STEP 4 : Mise à jour du message de succès
            setMessageTitle('success_title');
            setMessage('success');
            sessionStorage.removeItem('secretStripe');
            reservationDispatch({ paymentSuccess: true });

            // STEP 5 : Création du contact pour l'API externe
            const codeContact = await createContact({
              ...state.visitorsList[0],
              datenaissance: state?.visitorsList?.[0]?.datenaissance || '',
              password: '',
              adresse1: '',
              ville: '',
              langue: ''
            });

            // STEP 6 : Confirmation du paiement à l'API externe
            if (codeContact?.id) {
              const codeSale = await createSale({
                ...state,
                totalTTC: state.totalPrice,
                codeclient: codeContact?.id,
                sellLines: sales,
                lockedItems: state.lignePanierList
              });

              // STEP 7 : Mise à jour de l'ancienne réservation si elle existe
              if (state.oldReservation) {
                await updateOrder(
                  {
                    code_sale: codeSale,
                    code_contact: codeContact?.id
                  },
                  orderOld._id
                );
              }

              // STEP 8 : Mise à jour de la réservation actuelle
              await updateOrder(
                {
                  code_sale: codeSale,
                  code_contact: codeContact?.id
                },
                order._id
              );
            }
          } else {
            setMessageTitle('failed_title');
            setMessage('failed');
          }
        } catch (error) {
          // eslint-disable-next-line no-console
          console.error('Error during order processing:', error);
        } finally {
          setIsSubmitting(false); // Réinitialise pour permettre une nouvelle soumission
        }
      });

      await loadedForm.KR.showForm(loadedForm.result.formId);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Error displaying payment form:', error);
    }
  };

  useEffect(() => {
    (async () => {
      if (formToken && state.activeStep === 3 && !formDisplayed) {
        setFormDisplayed(true);
        await displayPaymentForm();
      }
    })();
  }, [formToken]);

  useEffect(() => {
    (async () => {
      if (state.activeStep === 3) await getFormToken();
    })();
  }, []);

  return (
    <div>
      <Helmet>
        <link
          rel="stylesheet"
          href="https://api.systempay.fr/static/js/krypton-client/V4.0/ext/classic-reset.css"
        />
        <script src="https://api.systempay.fr/static/js/krypton-client/V4.0/ext/classic.js"></script>
      </Helmet>
      <PopUpErrors
        t={t}
        open={alertOpen}
        text={errorText}
        handleClose={() => {
          setAlertOpen(false);
          setErrortext('general');
        }}
      />
      <div className="container">
        <div id="myPaymentForm"></div>
      </div>
    </div>
  );
};

SystemPay.propTypes = {
  state: PropTypes.shape({
    totalPrice: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    visitorsList: PropTypes.arrayOf(
      PropTypes.shape({
        datenaissance: PropTypes.string
      })
    ),
    selectedSlot: PropTypes.shape({}),
    receiverList: PropTypes.arrayOf(PropTypes.shape({})),
    ticketsList: PropTypes.arrayOf(PropTypes.shape({})),
    lignePanierList: PropTypes.arrayOf(PropTypes.shape({})),
    activeStep: PropTypes.number,
    oldReservation: PropTypes.shape({
      totalPrice: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      lignePanierList: PropTypes.arrayOf(PropTypes.shape({})),
      visitorsList: PropTypes.arrayOf(
        PropTypes.shape({
          datenaissance: PropTypes.string
        })
      ),
      selectedSlot: PropTypes.shape({}),
      receiverList: PropTypes.arrayOf(PropTypes.shape({})),
      ticketsList: PropTypes.arrayOf(PropTypes.shape({})),
      full_price: PropTypes.string,
      reduced_price: PropTypes.string,
      child_price: PropTypes.string,
      free_price: PropTypes.string
    })
  }).isRequired,
  setMessageTitle: PropTypes.func.isRequired,
  setMessage: PropTypes.func.isRequired,
  reservationDispatch: PropTypes.func.isRequired
};

export default withTrans(SystemPay);
